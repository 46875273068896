<template>
  <v-card>
    <v-container class="px-0 py-1">
      <v-card-title class="py-0">
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>
      <v-card-text class="py-0">
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="procesarArchivo()"
        >
          <v-row class="align-center justify-center">
            <!-- Tipo nomenclador -->
            <v-col cols="12" md="9" class="pb-0 text-center">
              <v-autocomplete
                v-model="tipoNomencladorSelected"
                :items="tiposNomenclador"
                label="Tipo nomenclador"
                item-value="id"
                item-text="value"
                :rules="rules.requiredAcceptZero"
                outlined
                clearable
                dense
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="align-center justify-center">
            <!-- base completa -->
            <v-col cols="12" md="6" class="py-0 text-center">
              <v-radio-group
                v-model="baseCompleta"
                mandatory
                class="py-0 mt-0 "
              >
                <v-radio class="py-0" :value="true">
                  <template v-slot:label>
                    <span>Base completa </span>
                    <v-tooltip right max-width="25rem">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          size="1.4rem"
                          color="primary"
                          class="pl-2"
                          v-on="on"
                          v-bind="attrs"
                        >
                          {{ infoIcon }}</v-icon
                        >
                      </template>
                      <span
                        >Actualiza el nomenclador farmacéutico de manera
                        completa con valores al día de la fecha</span
                      >
                    </v-tooltip>
                  </template>
                </v-radio>
                <v-radio class="py-0" :value="false">
                  <template v-slot:label>
                    <span>Solo novedades </span>
                    <v-tooltip right max-width="25rem">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          size="1.4rem"
                          color="primary"
                          class="pl-1"
                          v-on="on"
                          v-bind="attrs"
                        >
                          {{ infoIcon }}</v-icon
                        >
                      </template>
                      <span
                        >Actualiza el nomenclador farmacéutico desde la última
                        vez que se procesó una actualización (completa o
                        novedades)</span
                      >
                    </v-tooltip>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
            <!-- precio unitario -->
            <v-col cols="12" md="6" class="py-0 text-center">
              <v-switch
                class="my-0 py-0"
                v-if="parametroGeneralMuestraGrabaPrecioUnitario"
                v-model="grabarPrecioUnitario"
                label="Grabar precio unitario"
              ></v-switch>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="py-0">
        <v-spacer></v-spacer>
        <v-btn
          outlined
          :disabled="procesandoArchivo"
          @click="closeModal(false)"
        >
          Cancelar
        </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          :loading="procesandoArchivo"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>

      <!-- loader que muestra pasos de actualizacion -->
      <v-row
        align-content="center"
        align="center"
        justify="center"
        class="align-center justify-center"
      >
        <v-col md="12" sm="12" class="py-0 text-center">
          <v-stepper v-if="procesandoArchivo" alt-labels :value="procesoStep">
            <v-card flat class="py-0 px-0 " :loader-height="5">
              <v-stepper-header class="py-0 px-0">
                <v-stepper-step
                  step="1"
                  :color="procesoStep > 1 ? 'success' : 'primary'"
                  :complete="procesoStep > 1"
                  :complete-icon="checkIcon"
                  class="px-0 pb-0 pt-1"
                >
                  <v-text-field
                    dense
                    value="Consultando novedades"
                    :loading="procesoStep == 1"
                    loader-height="5"
                    class="some-style px-0 pt-2"
                    readonly
                  >
                  </v-text-field>
                </v-stepper-step>

                <v-stepper-step
                  step="2"
                  :color="procesoStep > 2 ? 'success' : 'primary'"
                  :complete-icon="checkIcon"
                  :complete="procesoStep > 2"
                  class="px-0 pb-0 pt-1"
                >
                  <v-text-field
                    dense
                    value="Grabando valores"
                    :loading="procesoStep == 2"
                    :disabled="procesoStep < 2"
                    loader-height="5"
                    class="some-style px-0 pt-2"
                    readonly
                  >
                  </v-text-field>
                </v-stepper-step>
              </v-stepper-header>
            </v-card>
          </v-stepper>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";

export default {
  name: "NuevoProcAlfaBeta",

  data: () => ({
    formEditTitle: "Nuevo proceso de actualización Alfa Beta",
    rules: rules,
    settingsIcon: enums.icons.SETTINGS,
    checkIcon: enums.icons.CHECK,
    clockIcon: enums.icons.TIME,
    infoIcon: enums.icons.SNB_INFO,

    isFormValid: false,
    procesandoArchivo: false,
    tiposNomenclador: [],
    tipoNomencladorSelected: null,
    grabarPrecioUnitario: false,
    parametroGeneralMuestraGrabaPrecioUnitario: false,
    procesoStep: 1,
    clave: null,
    ultimoLog: null,
    baseCompleta: true
  }),
  created() {
    this.setNomenFarmacia();
    this.setConfig();
  },
  methods: {
    ...mapActions({
      procesarArchivoActualizacionAlfaBeta:
        "prestadores/procesarArchivoActualizacionAlfaBeta",
      getNomFarmacia: "prestadores/getNomFarmacia",
      setAlert: "user/setAlert",
      getConfigActualizacionAB: "prestadores/getConfigActualizacionAB",
      grabarProcesoActualizacionAlfaBeta:
        "prestadores/grabarProcesoActualizacionAlfaBeta"
    }),
    async setConfig() {
      const response = await this.getConfigActualizacionAB();
      if (response != null) {
        this.parametroGeneralMuestraGrabaPrecioUnitario =
          response.parametroGeneralMuestraGrabaPrecioUnitario;
      }
    },
    async setNomenFarmacia() {
      const nomencladorFar = await this.getNomFarmacia();
      this.tiposNomenclador = nomencladorFar;
      if (this.tiposNomenclador.length == 1) {
        this.tipoNomencladorSelected = this.tiposNomenclador[0].id;
      }
    },
    async procesarArchivo() {
      this.procesandoArchivo = true;
      const data = {
        grabarPrecioUnitario: this.grabarPrecioUnitario,
        nomId: this.tipoNomencladorSelected,
        baseCompleta: this.baseCompleta
      };
      try {
        const res = await this.procesarArchivoActualizacionAlfaBeta(data);
        if (res.status === 200) {
          this.procesoStep += 1;
          this.clave = res.data.data.clave;
          this.ultimoLog = res.data.data.ultimoLog;
          const resGrabarProceso = await this.grabarProceso(
            this.clave,
            this.ultimoLog
          );
          if (resGrabarProceso) {
            this.procesoStep += 1;
            this.setAlert({ type: "success", message: "Guardado con éxito" });
            this.closeModal(true);
          }
        }
      } catch (error) {
        this.procesoStep = 1;
      } finally {
        this.procesandoArchivo = false;
        this.procesoStep = 1;
      }
    },
    async grabarProceso(clave, ultimoLog) {
      const data = {
        grabarPrecioUnitario: this.grabarPrecioUnitario,
        nomId: this.tipoNomencladorSelected,
        baseCompleta: this.baseCompleta,
        clave: clave,
        ultimoLog: ultimoLog ?? ""
      };
      try {
        const res = await this.grabarProcesoActualizacionAlfaBeta(data);
        if (res.status === 200) {
          return true;
        }
      } catch (error) {
        return false;
      }
    },

    closeModal(procesoCreado) {
      this.$emit("closeAndReloadNew", procesoCreado);
    }
  }
};
</script>

<style scoped>
.some-style >>> .v-input__slot::before {
  border-style: none !important;
}
::v-deep label {
  margin-bottom: 0;
}
</style>
