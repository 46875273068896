var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-container',[_c('v-card-title',{staticClass:"py-0"},[_c('span',{staticClass:"pl-1 primary--text"},[_vm._v(_vm._s(_vm.configTitle))])]),_c('v-card-text',{staticClass:"py-0"},[_c('v-form',{ref:"form",attrs:{"form":"form","id":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.saveConfig()}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","md":"12"}},[_c('v-text-field',{attrs:{"type":"text","outlined":"","dense":"","label":"URL del Web service","rules":_vm.rules.required.concat(
                  _vm.rules.requiredTrim(_vm.url),
                  _vm.rules.validateUrl
                ),"hint":"Formato de URL: http://gecros.com","persistent-hint":""},model:{value:(_vm.url),callback:function ($$v) {_vm.url=$$v},expression:"url"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"7"}},[_c('v-text-field',{attrs:{"type":"text","outlined":"","dense":"","label":"Usuario","persistent-hint":"","hint":"ID del usuario en Alfa Beta","rules":_vm.rules.required,"autocomplete":"off"},model:{value:(_vm.idUserAb),callback:function ($$v) {_vm.idUserAb=$$v},expression:"idUserAb"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"5"}},[_c('v-text-field',{attrs:{"append-icon":!_vm.passUserAb
                  ? ''
                  : _vm.currentPassTextType
                  ? 'mdi-eye'
                  : 'mdi-eye-off',"type":_vm.currentPassTextType ? 'password' : 'text',"outlined":"","dense":"","label":"Contraseña","autocomplete":"off","rules":_vm.rules.required},on:{"click:append":function () { return (_vm.currentPassTextType = !_vm.currentPassTextType); }},model:{value:(_vm.passUserAb),callback:function ($$v) {_vm.passUserAb=$$v},expression:"passUserAb"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-switch',{staticClass:"my-0 py-0",attrs:{"label":"Mostrar opción 'Grabar precio unitario' al generar proceso de actualización"},model:{value:(_vm.parametroGeneralMuestraGrabaPrecioUnitario),callback:function ($$v) {_vm.parametroGeneralMuestraGrabaPrecioUnitario=$$v},expression:"parametroGeneralMuestraGrabaPrecioUnitario"}})],1)],1)],1)],1),_c('v-card-actions',{staticClass:"py-0"},[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":""},on:{"click":_vm.closeModal}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"type":"submit","disabled":!_vm.isFormValid,"loading":_vm.isLoadingBtn,"form":"form","color":"primary"}},[_vm._v(" Guardar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }