<template>
  <v-card>
    <v-container>
      <v-card-title class="py-0">
        <span class="pl-1 primary--text">{{ configTitle }}</span>
      </v-card-title>
      <v-card-text class="py-0">
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="saveConfig()"
        >
          <v-row>
            <!-- Url-->
            <v-col cols="12" md="12" class="pb-0">
              <v-text-field
                type="text"
                outlined
                dense
                label="URL del Web service"
                v-model="url"
                :rules="
                  rules.required.concat(
                    rules.requiredTrim(url),
                    rules.validateUrl
                  )
                "
                hint="Formato de URL: http://gecros.com"
                persistent-hint
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <!-- Id del usuario-->
            <v-col cols="12" md="7" class="py-0">
              <v-text-field
                type="text"
                outlined
                dense
                label="Usuario"
                persistent-hint
                hint="ID del usuario en Alfa Beta"
                v-model="idUserAb"
                :rules="rules.required"
                autocomplete="off"
              ></v-text-field>
            </v-col>
            <!-- Contraseña del usuario-->
            <v-col cols="12" md="5" class="py-0">
              <v-text-field
                :append-icon="
                  !passUserAb
                    ? ''
                    : currentPassTextType
                    ? 'mdi-eye'
                    : 'mdi-eye-off'
                "
                @click:append="
                  () => (currentPassTextType = !currentPassTextType)
                "
                :type="currentPassTextType ? 'password' : 'text'"
                outlined
                dense
                label="Contraseña"
                v-model="passUserAb"
                autocomplete="off"
                :rules="rules.required"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="py-0">
              <v-switch
                class="my-0 py-0"
                v-model="parametroGeneralMuestraGrabaPrecioUnitario"
                label="Mostrar opción 'Grabar precio unitario' al generar proceso de actualización"
              ></v-switch>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="py-0">
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal">
          Cancelar
        </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          :loading="isLoadingBtn"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import GoBackBtn from "@/components/shared/GoBackBtn";

export default {
  name: "ConfigProcAlfaBeta",
  components: {
    GoBackBtn
  },
  data: () => ({
    configTitle: "Configuración del proceso de actualización Alfa Beta",
    rules: rules,
    routeToGo: "ProcesoActAlfaBeta",
    settingsIcon: enums.icons.SETTINGS,
    isFormValid: false,
    isLoadingBtn: false,
    abConfigId: null,
    url: null,
    idUserAb: null,
    passUserAb: null,
    currentPassTextType: String,
    parametroGeneralMuestraGrabaPrecioUnitario: false
    //archivo: ""
  }),
  created() {
    this.setConfig();
  },
  methods: {
    ...mapActions({
      getConfigActualizacionAB: "prestadores/getConfigActualizacionAB",
      saveConfigActualizacionAB: "prestadores/saveConfigActualizacionAB",
      setAlert: "user/setAlert"
    }),
    async setConfig() {
      const response = await this.getConfigActualizacionAB();
      if (response != null) {
        this.abConfigId = response.abConfigId;
        this.url = response.url;
        this.idUserAb = response.idUserAb;
        this.passUserAb = response.passUserAb;
        this.parametroGeneralMuestraGrabaPrecioUnitario =
          response.parametroGeneralMuestraGrabaPrecioUnitario;
      }
    },
    async saveConfig() {
      this.isLoadingBtn = true;
      const data = {
        abConfigId: this.abConfigId ?? null,
        url: this.url,
        idUserAb: this.idUserAb,
        passUserAb: this.passUserAb,
        parametroGeneralMuestraGrabaPrecioUnitario: this
          .parametroGeneralMuestraGrabaPrecioUnitario
      };
      try {
        const res = await this.saveConfigActualizacionAB(data);
        if (res === true) {
          this.isFormValid = true;
          this.setAlert({ type: "success", message: "Guardado con éxito" });
          this.isLoadingBtn = false;
          this.closeModal();
        }
      } catch {
        this.isLoadingBtn = false;
      }
    },
    closeModal() {
      this.$emit("closeAndReloadConfig");
    }
  }
};
</script>

<style scoped>
::v-deep label {
  margin-bottom: 0;
}
</style>
